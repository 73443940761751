import css from '@emotion/css'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import typeset from '../utils/typeset'
import { rhythm } from '../utils/typography'

class PostIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const coverPhoto = get(this, 'props.data.desktop.childImageSharp.fluid')
    const post = this.props.data.markdownRemark

    return (
      <Layout
        location={this.props.location}
        cover={
          <>
            <GatsbyImage
              style={{ maxHeight: '100vh' }}
              className="cover-image"
              fluid={coverPhoto}
            />
            <h1
              css={css`
                font-size: 10vw;
                margin: auto;
                z-index: 1;
                color: white;
                text-shadow: 0px 0px 0.75vw black;
                margin-top: -2em;
                margin-bottom: 0.85em;
                @media (max-width: 767px) {
                  display: none;
                }
              `}
            >
              Illusions of Power
            </h1>
            <span
              css={css`
                margin: auto;
                &::before {
                  border-style: solid;
                  border-color: white;
                  opacity: 0.8;
                  border-width: 0.2em 0.2em 0 0;
                  content: '';
                  display: inline-block;
                  height: 1em;
                  left: 0.15em;
                  position: relative;
                  top: 0.15em;
                  transform: rotate(-45deg);
                  vertical-align: top;
                  width: 1em;
                }
                &:before {
                  margin-top: -2.5em;
                  top: 0;
                  transform: rotate(135deg);
                }
                @media (max-width: 767px) {
                  display: none;
                }
              `}
            />
          </>
        }
      >
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: typeset(post.html),
          }}
          css={css`
            margin-top: ${rhythm(1)};
            h1:not(:first-of-type) {
              margin-top: ${rhythm(3/2)};
            }
            hr {
              background: none;
              margin-bottom: ${rhythm(2)};
              &:after {
                content: '· · ·';
                word-spacing: ${rhythm(1)};
                display: block;
                text-align: center;
              }
            }
          `}
        />
      </Layout>
    )
  }
}

export default PostIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    desktop: file(relativePath: { eq: "burning_newspaper.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(fields: { slug: { eq: "/landing/" } }) {
      id
      html
      frontmatter {
        title
        author
        date(formatString: "MMMM DD, YYYY")
        tags
        template
      }
      fields {
        tagSlugs
        readingTime {
          text
        }
      }
    }
  }
`
